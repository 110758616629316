
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import Master from "@/models/MasterModel";
import * as Mast from "@/models/MasterModel";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import BnPHelpers from "@/assets/ts/_utils/BnPHelpers";

export default defineComponent({
  name: "masters-table",
  components: {},
  props: {
    widgetClasses: String,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { t } = useI18n();
   
    const masters = ref<Master[]>([] as Master[]);
    let updateMasterStateTimer: number;

    let masterStates = {};
    
    const getgetMasters = () => {
      const postData = {
        searchString: "",
        parentIds: [],
        pageSize: 30, //todo geert is dit genoeg
        pageNumber: "",
      } as any;
      return ApiService.post("/device/masters", postData)
        .then(({ data }) => {
          console.log("getmasters response data:");
          console.log({ ...data });
          masters.value = data.results;
        })
        .catch(({ response }) => {
          console.log("Fout getMasters");
        });
    };

    const clickNewMaster = () => {
      console.log("goNewMaster");

      Swal.fire({
        title: "Enter device serialnumber",
        input: "text",
        inputValue: "",
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return "You need to write something!";
          }
        },
        buttonsStyling: true,
        confirmButtonText: "Ok",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          registerMaster(result.value);
        }
      });
    };

    const registerMaster = (serial) => {
      ApiService.post("/device/masterregister", { serial: serial } as any)
        .then(({ data }) => {
          console.log("registermaster response data:");
          console.log(data);
          getgetMasters();
        })
        .catch(({ response }) => {
          console.log("Fout masterregister");
          console.log(response);
          Swal.fire({
            text: ApiService.getModelStateError(response.data.errors), //todo algemene error message
            icon: "error",
            buttonsStyling: true,
            confirmButtonText: "Ok",
          });
        });
    };

    const masterHearbeat = (master) => {
      ApiService.get2("/device/masterheartbeat/?id=" + master.id)
        .then(({ data }) => {
          //console.log("masterHearbeat response data:");
          //console.log(data);
        })
        .catch(({ response }) => {
          //console.log("Fout masterHearbeat");
          //console.log(response);
        });
    };

    const masterResponse = (master) => {
      ApiService.get2("/device/masterresponse/?id=" + master.id)
        .then(({ data }) => {
          if (data.timestamp == "0001-01-01T00:00:00") {
            master.status = "nok";
          } else { //console.log(master.statusTime +"   "+ data.timestamp);
            if (master.statusTime != new Date(data.timestamp).toLocaleString()) {
              if(master.statusTime!=undefined) master.status = "ok";
              master.statusTime = new Date(data.timestamp).toLocaleString()
              master.statusTimeRelative = BnPHelpers.getRelativeTime(new Date(data.timestamp));
            }else{
               master.status = "nok";
               master.statusTime = new Date(data.timestamp).toLocaleString()
              master.statusTimeRelative = BnPHelpers.getRelativeTime(new Date(data.timestamp));
            }
          }
        })
        .catch(({ response }) => {
          console.log("Fout masterresponse");
          console.log(response);
        });
    };

    const updateStateMasters = () => {
      if (masters.value.length > 0) {
        
        masters.value.forEach(function (master) {
          masterHearbeat(master);
          setTimeout(masterResponse, 2000, master);
        });
      }
    };

    const clickEditMaster = (data) => {
      console.log("goEditMaster");
      console.log(data);
      store.commit("setMasterId", data.id);

      router.push({ name: "editmaster" });
    };

    const clickDeleteMaster = (data: Master) => {
      Swal.fire({
        title: "Are you sure to delete master " + data.name + " ?",
        showCancelButton: true,
        icon: "warning",
        buttonsStyling: true,
        confirmButtonText: "Ok",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteMaster(data);
        }
      });
    };

    const deleteMaster = (data) => {
      ApiService.post("/device/masterdelete", data as any)
        .then(({ data }) => {
          console.log("delete master response data:");
          console.log({ ...data });
          getgetMasters();
        })
        .catch(({ response }) => {
          console.log("Fout masterdelete");
        });
    };

    const clickPublishMasterConfig = (data: Master) => {
      publishMasterConfig(data.id);
    };

    const clickMasterConfiguration = (data: Master) => {
      console.log("clickMasterComfiguration");
      console.log(data);
      masterConfiguration(data.id);
    };

    const publishMasterConfig = (id) => {
      ApiService.get2("/device/mastervalidateconfig?id=" + id)
        .then(({ data }) => {
          console.log("mastervalidateconfig master response data:");
          console.log({ ...data });
          return ApiService.get2("/device/masterpublishconfig?id=" + id);
        })
        .then(({ data }) => {
          console.log("masterpublishconfig master response data:");
          console.log({ ...data });
          Swal.fire({
            text: "Published", //todo algemene error message
            icon: "success",
            buttonsStyling: true,
            confirmButtonText: "Ok",
          });
        })
        .catch((response) => {
          console.log("fout publishMasterConfig");
          console.log(response);
          Swal.fire({
            text: ApiService.getModelStateError(response.errors), //todo algemene error message
            icon: "error",
            buttonsStyling: true,
            confirmButtonText: "Ok",
          });
        });
    };

    const masterConfiguration = (id) => {
      return ApiService.get2("/device/masterconfiguration?id=" + id)
        .then(({ data }) => {
          console.log("publishMasterConfig master response data:");
          console.log(data);
          Swal.fire({
            text: JSON.stringify(data),
            icon: "info",
            buttonsStyling: true,
            confirmButtonText: "Ok",
          });
        })
        .catch((response) => {
          console.log("fout masterConfiguration");
          console.log(response);
          Swal.fire({
            text: ApiService.getModelStateError(response.errors), //todo algemene error message
            icon: "error",
            buttonsStyling: true,
            confirmButtonText: "Ok",
          });
        });
    };

    onMounted(() => {
      getgetMasters().then((x) => {
        updateStateMasters();
        updateMasterStateTimer = setInterval(updateStateMasters, 10000);
      });
    });

    onUnmounted(() => {
      clearInterval(updateMasterStateTimer);
    });

    return {
      t,
      masters,
      clickNewMaster,
      clickEditMaster,
      clickDeleteMaster,
      updateStateMasters,
      clickPublishMasterConfig,
      clickMasterConfiguration,
    };
  },
});
